
square-instinct-environment {
  .radio-dropdown-container {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .instinct-radio-btn {
    margin-right: 10px;
  }
}
